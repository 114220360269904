<template>
    <div>
        <div class="uni-header">
     
            
			<el-form ref="queryForm" size="small" :inline="true"  label-width="68px">
			  <el-form-item label="名称" prop="keys">
				  <el-input
				    v-model="searchVal"
				    placeholder="请输入名称"
				    clearable
				    @keyup.enter.native="search"
				  />
			  </el-form-item>
			  
			  <el-form-item label="时间" prop="keys">
				  <el-date-picker style="margin-right: 20px;" type="date" format="yyyy-MM-dd" clearable value-format="yyyy-MM-dd"
									  v-model="startDate" size="small" placeholder="开始日期" >
				  </el-date-picker>
				  <el-date-picker type="date" style="margin-right: 20px;" format="yyyy-MM-dd" clearable value-format="yyyy-MM-dd"
									  v-model="endDate" size="small" placeholder="结束日期" >
				  </el-date-picker>
			  </el-form-item>
			  <el-form-item>
			    <el-button type="primary" icon="el-icon-search" size="mini" @click="search">搜索</el-button>
			  </el-form-item>
			</el-form>
			
			
        </div>
        <div class="uni-container">
			
			<el-table :v-loading="loading" :data="tableData"  style="width: 100%">
				  
				  <el-table-column  label="接诊医生" prop="patient_name" width="250" >
				  	<template slot-scope="scope">			
				  		<div class="name">{{scope.row.doctorName}}</div>
				  		<div class="name">{{scope.row.doctorHospitalName}}</div>
				  					
				  	</template>
				  </el-table-column>
				  
					<el-table-column  label="申请医生" prop="patient_name" width="250" >
					  <template slot-scope="scope">			
							  <div class="name">{{scope.row.userName}}</div>
							   <div class="name">{{scope.row.userHospitalName}}</div>
						
					  </template>
					</el-table-column>
				  
					
				  
					<el-table-column  label="患者" prop="paName" width="120" ></el-table-column>
					<el-table-column  label="状态" prop="audit" width="180" >
						<template slot-scope="scope">
							{{consultationAudit[scope.row.audit].text}}
						</template>
					</el-table-column>
					<el-table-column  label="入院时间" prop="startTime" />
					
					 
					 
			</el-table>
			<!-- 
            <uni-table :loading="loading" border stripe type="selection" :emptyText="$t('common.empty')" @selection-change="selectionChange">
                <uni-tr>
                    <uni-th >接诊医生</uni-th>
                    <uni-th >申请医生</uni-th>
                    <uni-th width="120" align="center">转诊患者</uni-th>
					<uni-th width="120" align="center">状态</uni-th>
                    <uni-th width="190" align="center">时间</uni-th>
                </uni-tr>
                <uni-tr v-for="(item ,index) in tableData" :key="index">
                    <uni-td>
						
						<div class="name">{{item.userName}}</div>
						<div class="name">{{item.userHospitalName}}</div>
						
					</uni-td>
                    <uni-td>
						  <div class="name">{{item.doctorName}}</div>
                        <div class="name">{{item.doctorHospitalName}}</div>
                    </uni-td>
                    <uni-td>{{item.name}}</uni-td>
					 <uni-td>
						
						{{consultationAudit[item.audit].text}}
						
						
						
						
						
					</uni-td>
					<uni-td>{{item.startTime}}</uni-td>
                  
                </uni-tr>
            </uni-table>
            <div class="uni-pagination-box">
                <uni-pagination show-icon :page-size="pageSize" :current="pageCurrent" :total="total" @change="change" />
            </div> -->
        </div>
		
    </div>
</template>

<script>
	
	import config from '@/common/config.js'
    export default {
        data() {
            return {
				thisUser:config.getLoginInfo(),
				startDate: '',
				endDate: '',
				
                searchVal: '',
                tableData: [],
				consultationAudit:[
				 {text:'等待专家确认',value:'toBeConfirmed'},{text:'等待开始',value:'notStarted'}
				,{text:'专家拒绝',value:'refused'},{text:'结束',value:'finished'}
				,{text:'投诉',value:'complained'},{text:'结算',value:'settlement'}
				,{text:'取消',value:'cancel'},
				{text:'未支付',value:'noPay'}],
                // 每页数据量
                pageSize: 10,
                // 当前页
                pageCurrent: 1,
                // 数据总量
                total: 0,
                loading: false,
			pickerOptionsStart: {
			        disabledDate: time => {
			          const endDateVal = new Date(this.endDate).getTime()
			          if (endDateVal) {
			            return time.getTime() > endDateVal - 0
			          }
			        }
			},
			pickerOptionsEnd: {
			        disabledDate: time => {
			          const beginDateVal = new Date(this.startDate).getTime()
			          if (beginDateVal) {
			            return time.getTime() < beginDateVal - 0
			          }
			        }
			}
            }
        },
        created() {
            this.selectedIndexs = []
            this.getData(1)
        },
        methods: {
            // 多选处理
            selectedItems() {
                return this.selectedIndexs.map(i => this.tableData[i])
            },
            // 多选
            selectionChange(e) {
                console.log(e.detail.index);
                this.selectedIndexs = e.detail.index
            },
            //批量删除
            delTable() {
                console.log(this.selectedItems());
            },
            // 分页触发
            change(e) {
                this.getData(e.current)
            },
            // 搜索
            search() {
                this.getData(1, this.searchVal)
            },
			toAdd(){
				uni.navigateTo({
					url:"/pages/appointment/addConsultment/queConsultment"
				})
			},
			
            // 获取数据
            getData(pageCurrent, value = "") {
                this.loading = true
                this.pageCurrent = pageCurrent
				var _this=this;				
				config.ajaxAw("/kongtangweishi/api/consultation/listAll",
				{districtId:this.thisUser.user.districtId,
				
				hospitalId:this.thisUser.doctor.hospital_id,
				 keys:value,
				 startDate:_this.startDate,
				 endDate:_this.endDate,
				 page:pageCurrent
				},function(data){
					_this.tableData = data.content
					_this.total = data.totalElements
					_this.loading = false
				})
				
                // this.request({
                //     pageSize: this.pageSize,
                //     pageCurrent: pageCurrent,
                //     value: value,
                //     success: (res) => {
                //         // console.log('data', res);
                //         this.tableData = res.data
                //         this.total = res.total
                //         this.loading = false
                //     }
                // })
            }
           

        }
    }
</script>

<style>
	/* #ifndef H5 */
	page {
		padding-top: 85px;
	}
	/* #endif */
</style>
